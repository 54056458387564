import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import BookingPaymentServices from "../../api/services/bookingPayment";
import {useNavigate} from "react-router-dom";


// Load your Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const useStripeAccountToken = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    const createAccountToken = async (partner) => {
        setIsLoading(true);
        setError(null);
        const stripe = await stripePromise;

        const tokenData = {
            business_type: "company",
            company: {
                name: partner.name,
                address: {
                    line1: partner.address,
                    postal_code: "49070"
                },
            },
            tos_shown_and_accepted: true,
        };

        stripe.createToken('account', tokenData).then(result => {
            if (result.error) {
                setError(result.error.message);
                setIsLoading(false);
            } else {
                setToken(result.token.id);
                setIsLoading(false);
                createAccount(result.token.id, partner.engineID.bookingEmail, partner._id);
            }
        }).catch(err => {
            setError('An unexpected error occurred');
            setIsLoading(false);
        });
    };

    const createAccount = async (accountToken, email, partnerID) => {
        BookingPaymentServices.createConnectAccount({ email, accountToken, partnerID }).then((response) => {
            //do not open in other tab
            window.location.href = response.data.url;
        }).catch((error) => {
            console.log(error);
        })
    }

    return {
        createAccountToken,
        isLoading,
        error,
        token
    };
};
